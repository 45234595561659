import Button from '@aurora/shared-client/components/common/Button/Button';
import { ButtonVariant } from '@aurora/shared-client/components/common/Button/enums';
import { IconColor, IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import Icon from '@aurora/shared-client/components/common/Icon/Icon';
import {
  ToastAlertVariant,
  ToastVariant
} from '@aurora/shared-client/components/common/ToastAlert/enums';
import type ToastProps from '@aurora/shared-client/components/common/ToastAlert/ToastAlertProps';
import useCommunitySsoProperties from '@aurora/shared-client/components/community/useCommunitySsoProperties';
import useFrameEnd, {
  getParentFrameId
} from '@aurora/shared-client/components/context/AnalyticsParentFrames/useFrameEnd';
import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import SsoRegistrationContext from '@aurora/shared-client/components/context/SsoRegistrationContext/SsoRegistrationContext';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import useToasts from '@aurora/shared-client/components/context/ToastContext/useToasts';
import useMessagePolicies from '@aurora/shared-client/components/messages/useMessagePolicies';
import useMutationWithTracing from '@aurora/shared-client/components/useMutationWithTracing';
import useQueryWithTracing from '@aurora/shared-client/components/useQueryWithTracing';
import useRegistrationStatus from '@aurora/shared-client/components/users/useRegistrationStatus';
import { hasErrors } from '@aurora/shared-client/helpers/apollo/ApolloHelper';
import Icons from '@aurora/shared-client/icons';
import { AuthFlow } from '@aurora/shared-client/types/enums';
import type {
  Article,
  Idea,
  IdeaTopicMessage,
  Message
} from '@aurora/shared-generated/types/graphql-schema-types';
import {
  ContentWorkflowState,
  ConversationStyle,
  RegistrationStatus
} from '@aurora/shared-generated/types/graphql-schema-types';
import { LocalStorageKeys } from '@aurora/shared-types/community/enums';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import { isPendingActionExpired } from '@aurora/shared-utils/helpers/anonymousUserActions/AnonymousUserActionsHelper';
import { checkPolicy } from '@aurora/shared-utils/helpers/objects/PolicyResultHelper';
import { getLog } from '@aurora/shared-utils/log';
import dynamic from 'next/dynamic';
import type { ReactElement } from 'react';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import { useLocalStorage } from 'react-use';
import { clearMessageKudosCache } from '../../../helpers/messages/MessageHelper/MessageHelper';
import useGlobalState, { GlobalStateType } from '@aurora/shared-client/helpers/ui/GlobalState';
import type { AnonymousUserAction } from '../../../types';
import { AnonAction, MessageViewVariant } from '../../../types/enums';
import type {
  GiveKudoToMessageMutation,
  IdeaStatusInformationQuery,
  IdeaStatusInformationQueryVariables,
  RevokeKudoFromMessageMutation
} from '../../../types/graphql-types';
import { UrlObject, useContextObjectRefFromUrl } from '../../context/useContextObjectFromUrl';
import ideaStatusPropertiesQuery from '../../ideas/IdeaStatusInformation.query.graphql';
import type { KudosProps } from '../../messages/MessageView/types';
import useTranslation from '../../useTranslation';
import giveKudoToMessageMutation from './GiveKudoToMessage.mutation.graphql';
import kudoedMessageQuery from './KudoedMessage.query.graphql';
import localStyles from './KudosButton.module.pcss';
import revokeKudoFromMessageMutation from './RevokeKudoFromMessage.graphql';
import { useIsPreviewMode } from '../../messages/useCurrentOrPreviewMessage';
import EmailVerificationContext from '../../context/EmailVerificationContext/EmailVerificationContext';
import ThemeContext from '@aurora/shared-client/components/context/ThemeContext/ThemeContext';
import type { CSSPropertiesWithVars } from '@aurora/shared-client/helpers/styles/CSSPropertiesWithVarsHelper';
import useSafeColor from '@aurora/shared-client/components/useSafeColor';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import useAuthFlow from '@aurora/shared-client/components/useAuthFlow';
import { updatedMessageForDeepLinkVar } from '../../messages/MessageDeepLink/MessageReactVarHelper';

const KudosListPager = dynamic(
  () => import(/* webpackChunkName: "modal-KudosListPager" */ '../KudosListPager/KudosListPager'),
  { ssr: false }
);

const log = getLog(module);

/**
 * Kudos button for a message.
 *
 * @constructor
 *
 * @author Dolan Halbrook, Adam Ayres, Willi Hyde
 */
const KudosButton: React.FC<React.PropsWithChildren<KudosProps>> = ({
  kudosSum,
  message,
  kudo,
  canKudo = null,
  revisionNum,
  messageType,
  className,
  useCount = true,
  useText = true,
  view
}) => {
  const cx = useClassNameMapper(localStyles);
  const { addToast } = useToasts();
  const { isAnonymous, isFullyRegistered, isPartiallyRegistered } = useRegistrationStatus();
  const [, setMessageEditingState] = useGlobalState(GlobalStateType.MESSAGE_EDITING_STATE);
  const { authUser } = useContext(AppContext);
  const { formatMessage, loading: textLoading } = useTranslation(EndUserComponent.KUDOS_BUTTON);
  const { confirmEmailStatus } = useRegistrationStatus();
  const { router } = useEndUserRoutes();
  const {
    data,
    loading: ssoPropertiesLoading,
    ready: ssoPropertiesReady
  } = useCommunitySsoProperties(module);
  const { registrationStatus } = useRegistrationStatus();
  const { showSsoRegistrationModal } = useContext(SsoRegistrationContext);
  const [isActive, setIsActive] = useState(false);
  const { id: messageId } = message;
  const [addKudo, { client }] = useMutationWithTracing<GiveKudoToMessageMutation>(
    module,
    giveKudoToMessageMutation
  );
  const [removeKudo] = useMutationWithTracing<RevokeKudoFromMessageMutation>(
    module,
    revokeKudoFromMessageMutation
  );
  const { addUpdateEmailToast } = useContext(EmailVerificationContext);
  const [frameEnd] = useFrameEnd();
  const [anonymousUserAction, setAnonymousUserAction] = useLocalStorage<AnonymousUserAction>(
    LocalStorageKeys.ANONYMOUS_USER_ACTION_KEY,
    null
  );
  const isPreviewMode = useIsPreviewMode();
  const isMessagePreviewAndDraft =
    isPreviewMode && (message as Article)?.contentWorkflow?.state !== ContentWorkflowState.Publish;
  const isAuthor = authUser?.id === message?.author?.id;
  const { conversationStyle } = message.board;
  const { ssoProperties } = data?.community ?? {};
  const tenant = useContext(TenantContext);
  const {
    publicConfig: { multiAuthEnabled }
  } = tenant;

  const { theme } = useContext(ThemeContext);
  const ideaColor = theme?.coreTypes?.ideaColor;
  const safeColorCallback = useSafeColor();
  const ideaTextColor = safeColorCallback(ideaColor).isDark()
    ? theme?.yiq?.light
    : theme?.yiq?.dark;
  const ideaTextStyles: CSSPropertiesWithVars = {
    '--lia-d-idea-text-color': ideaTextColor
  };

  const { data: policiesData } = useMessagePolicies(
    module,
    {
      id: messageId,
      useCanKudo: true
    },
    !message || canKudo !== null
  );
  const { triggerAuthFlow } = useAuthFlow();

  const { data: propertiesData, loading: propertiesLoading } = useQueryWithTracing<
    IdeaStatusInformationQuery,
    IdeaStatusInformationQueryVariables
  >(module, ideaStatusPropertiesQuery, {
    variables: {
      id: message.board.id,
      useIdeaStatusProperties: true
    },
    skip: conversationStyle !== ConversationStyle.Idea
  });

  const kudosWeight = authUser?.kudosWeight ?? 1;
  const weight = kudosWeight || 1;
  const kudoed = kudo;
  const ssoEnabled = checkPolicy(ssoProperties?.ssoEnabled);
  const showKudosAction =
    checkPolicy(canKudo ?? policiesData?.message?.messagePolicies?.canKudo) &&
    !isMessagePreviewAndDraft;
  const showLikeButton = conversationStyle !== ConversationStyle.Idea || message.depth !== 0;
  const { id: replyId } = useContextObjectRefFromUrl(UrlObject.REPLY);
  const isPermalinkReply = replyId !== null && replyId === messageId;
  const giveOrRemoveKudo = useCallback(
    async (clearPending = false) => {
      async function rollback(): Promise<void> {
        await client.query({
          query: kudoedMessageQuery,
          variables: { id: messageId },
          fetchPolicy: 'network-only'
        });
      }
      function renderError(title: string, textKey: string, errorId: string): void {
        const id = `message-${errorId}-kudos-${textKey}`;

        const toastProps: ToastProps = {
          toastVariant: ToastVariant.BANNER,
          alertVariant: ToastAlertVariant.DANGER,
          title: formatMessage(title, { showLikeButton }),
          autohide: false,
          message: formatMessage(textKey, { showLikeButton }),
          id
        };
        addToast(toastProps);
      }
      const parentFrameId = getParentFrameId();
      if (kudoed) {
        const { errors } = await removeKudo({
          variables: { messageId },
          optimisticResponse: {
            revokeKudoFromMessage: {
              result: {
                message: {
                  id: messageId,
                  kudosSumWeight: kudosSum - weight,
                  hasGivenKudo: false,
                  revisionNum: revisionNum,
                  __typename: messageType
                },
                __typename: 'Kudo'
              },
              errors: null
            }
          },
          update: (cache, result): void => {
            const { data: queryData, errors: queryErrors } = result;
            if (!queryErrors?.length && !queryData?.revokeKudoFromMessage?.errors) {
              if (clearPending) {
                setAnonymousUserAction(null);
              }
              if (isPermalinkReply) {
                const { hasGivenKudo, kudosSumWeight } =
                  queryData?.revokeKudoFromMessage?.result?.message;
                updatedMessageForDeepLinkVar({
                  ...message,
                  hasGivenKudo,
                  kudosSumWeight
                } as Message);
              }
              clearMessageKudosCache(cache, messageType, messageId);
            }
          },
          onCompleted: (d): void => {
            if (!hasErrors(d.revokeKudoFromMessage)) {
              frameEnd({
                context: { parentFrameId }
              });
            }
          }
        });
        if (errors?.length > 0) {
          log.error('Unable to remove kudo: %O', errors);
          await rollback();
          renderError('errorHeader', 'errorRemove', messageId);
        }
      } else {
        const { data: addKudoData, errors } = await addKudo({
          variables: { messageId },
          optimisticResponse: {
            giveKudoToMessage: {
              result: {
                message: {
                  id: messageId,
                  kudosSumWeight: kudosSum + weight,
                  hasGivenKudo: true,
                  revisionNum: revisionNum,
                  __typename: messageType
                },
                __typename: 'Kudo'
              },
              errors: null
            }
          },
          update: (cache, result): void => {
            const { data: queryData, errors: queryErrors } = result;
            if (!queryErrors?.length && !hasErrors(queryData?.giveKudoToMessage)) {
              if (clearPending) {
                setAnonymousUserAction(null);
              }
              if (isPermalinkReply) {
                const { hasGivenKudo, kudosSumWeight } =
                  queryData?.giveKudoToMessage?.result?.message;
                updatedMessageForDeepLinkVar({
                  ...message,
                  hasGivenKudo,
                  kudosSumWeight
                } as Message);
              }
              clearMessageKudosCache(cache, messageType, messageId);
            }
          },
          onCompleted: (d): void => {
            if (!hasErrors(d.giveKudoToMessage)) {
              frameEnd({
                context: { parentFrameId }
              });
            }
          }
        });
        if (errors?.length > 0) {
          log.error('Unable to add kudo: %O', errors);
          await rollback();
          renderError('errorHeader', 'errorAdd', messageId);
        }
        const knownErrors = addKudoData?.giveKudoToMessage?.errors;
        if (
          knownErrors?.length > 0 &&
          knownErrors[0].__typename === 'GiveKudoToMessageLimitReachedError'
        ) {
          renderError('kudoLimit.error.title', 'kudoLimit.error.message', messageId);
        }
      }
    },
    [
      addKudo,
      kudoed,
      kudosSum,
      messageId,
      removeKudo,
      revisionNum,
      setAnonymousUserAction,
      weight,
      addToast,
      client,
      formatMessage,
      messageType,
      message,
      isPermalinkReply,
      showLikeButton,
      frameEnd
    ]
  );

  const performPendingAction = useCallback(async () => {
    function renderInfo(): void {
      const id = 'message-own-kudos-info';

      const toastProps: ToastProps = {
        toastVariant: ToastVariant.FLYOUT,
        alertVariant: ToastAlertVariant.INFO,
        title: formatMessage('info.header', { showLikeButton }),
        autohide: true,
        message: formatMessage('info.description', { showLikeButton }),
        id
      };
      addToast(toastProps);
    }

    if (!confirmEmailStatus) {
      setAnonymousUserAction(null);
      addUpdateEmailToast();
    } else if (!isPendingActionExpired(anonymousUserAction) && showKudosAction && !kudoed) {
      await giveOrRemoveKudo(true);
    } else {
      if (isAuthor) {
        renderInfo();
      }
      setAnonymousUserAction(null);
    }
  }, [
    addToast,
    addUpdateEmailToast,
    anonymousUserAction,
    showKudosAction,
    confirmEmailStatus,
    formatMessage,
    giveOrRemoveKudo,
    isAuthor,
    kudoed,
    setAnonymousUserAction,
    showLikeButton
  ]);

  useEffect(() => {
    if (anonymousUserAction !== null && anonymousUserAction?.action === AnonAction.KUDOS) {
      if (isFullyRegistered && anonymousUserAction?.context.messageId === messageId && !!canKudo) {
        performPendingAction();
      } else if (isPartiallyRegistered && ssoEnabled) {
        // case when the user has already closed the complete registration modal before,
        // logs out and trys to perform kudo action -> open Complete Registration Modal
        localStorage.setItem(`${LocalStorageKeys.SSO_REGISTRATION_MODAL}.${authUser.uid}`, 'false');
        showSsoRegistrationModal(true, () => {
          const userAction: AnonymousUserAction = {
            action: AnonAction.KUDOS,
            context: anonymousUserAction.context,
            timeStamp: Date.now()
          };
          setAnonymousUserAction(userAction);
        });
        setAnonymousUserAction(null);
      }
    }
  }, [
    anonymousUserAction,
    authUser,
    messageId,
    canKudo,
    isFullyRegistered,
    isPartiallyRegistered,
    performPendingAction,
    setAnonymousUserAction,
    showSsoRegistrationModal,
    ssoEnabled,
    frameEnd
  ]);

  if (textLoading || ssoPropertiesLoading || propertiesLoading) {
    return null;
  }

  async function handleKudoEvent(event: React.MouseEvent<HTMLButtonElement>): Promise<void> {
    if (isAnonymous) {
      const userAction: AnonymousUserAction = {
        action: AnonAction.KUDOS,
        context: { messageId },
        timeStamp: Date.now()
      };

      setAnonymousUserAction(userAction);
      await triggerAuthFlow(
        () => {
          if (!ssoEnabled) {
            setMessageEditingState(null);
          }
        },
        multiAuthEnabled ? AuthFlow.MULTI_AUTH_LOGIN : AuthFlow.LOGIN,
        router.getCurrentRouteAndParams(),
        router.path
      );
    } else if (registrationStatus == RegistrationStatus.PartiallyRegistered) {
      // open complete registration modal
      // perform the kudos action on success of complete registration
      showSsoRegistrationModal(true, () => {
        const userAction: AnonymousUserAction = {
          action: AnonAction.KUDOS,
          context: { messageId },
          timeStamp: Date.now()
        };
        setAnonymousUserAction(userAction);
      });
    } else if (!confirmEmailStatus) {
      addUpdateEmailToast();
    } else if (showKudosAction) {
      event.preventDefault();
      setIsActive(true);
      // NOTE: current user is implied by auth against backend
      await giveOrRemoveKudo();
    }
  }

  const kudosCountClass = 'lia-g-action-btn lia-kudo-count';

  const KudosCountTitle: React.FC<React.PropsWithChildren<unknown>> = () => (
    <span className={cx('lia-g-is-number')}>{formatMessage('count', { count: kudosSum })}</span>
  );

  const stopVotingOnClosedIdeas = (propertiesData?.coreNode as Idea)?.ideaStatusProperties
    ?.stopVotesOnClosedIdeas;
  const messageStatusGroupKey = (message as IdeaTopicMessage)?.status?.statusGroupKey;
  const isVotingComplete =
    (messageStatusGroupKey === 'closed' && stopVotingOnClosedIdeas) ||
    messageStatusGroupKey === 'completed';
  const disableVoting =
    (isFullyRegistered && (!showKudosAction || isVotingComplete)) ||
    (!isFullyRegistered && !ssoPropertiesReady);
  const isInlineMessageView = view === MessageViewVariant.INLINE;
  const isCardMessageView = view === MessageViewVariant.CARD;
  const isStandardMessageView = view === MessageViewVariant.STANDARD;

  /**
   * Renders the tooltip text for the button
   */
  function getTooltipText(): string {
    return isAnonymous
      ? formatMessage('signInToVote', { count: kudosSum })
      : isVotingComplete
      ? formatMessage('votingComplete', { count: kudosSum })
      : isAuthor
      ? formatMessage('authorVote', { count: kudosSum })
      : disableVoting
      ? formatMessage('disableVote', { count: kudosSum })
      : kudoed
      ? formatMessage('voted', { count: kudosSum })
      : formatMessage('vote', { count: kudosSum });
  }

  /**
   * Renders the icon for the vote button
   */
  function getVoteIcon(): ReactElement {
    return (
      <Icon
        icon={disableVoting ? Icons.LockIcon : kudoed ? Icons.CheckmarkIcon : Icons.ArrowUpIcon}
        size={isInlineMessageView ? IconSize.PX_14 : IconSize.PX_16}
        color={
          isStandardMessageView
            ? IconColor.WHITE
            : !disableVoting && kudoed
            ? IconColor.IDEA
            : IconColor.GRAY_700
        }
        className={cx('lia-vote-icon')}
        testId="VoteIcon"
      />
    );
  }
  /**
   * Renders the vote count
   */
  const VoteCountTitle: React.FC<React.PropsWithChildren<unknown>> = () => (
    <div className={cx({ 'h-100': isStandardMessageView && kudosSum > 0 })}>
      <span
        className={cx(
          'lia-vote-count-card',
          {
            'lia-vote-count-kudoed': !disableVoting && kudoed && isCardMessageView
          },
          {
            'lia-vote-text-kudoed': (isStandardMessageView && kudoed) || disableVoting
          },
          { 'h-100': kudosSum > 0 }
        )}
      >
        {kudosSum}
      </span>
    </div>
  );

  /**
   * Renders the modal to display the kudos list
   */
  function renderKudosListModal(
    linkTitle: React.FC<React.PropsWithChildren<unknown>>,
    modalClassName?: string
  ) {
    return (
      <KudosListPager
        className={cx(modalClassName)}
        kudosSum={kudosSum}
        linkTitle={linkTitle}
        messageId={messageId}
        messageType={messageType}
        conversationStyle={conversationStyle}
        isRootMessage={message.depth === 0}
      />
    );
  }

  /**
   * Renders the vote component for inline view
   */
  function renderVoteComponentForInlineView() {
    return (
      <Button
        aria-label="vote"
        onClick={handleKudoEvent}
        title={getTooltipText()}
        variant={ButtonVariant.NO_VARIANT}
        style={ideaTextStyles}
        className={cx(
          'lia-vote-button-inline',
          {
            'lia-vote-completed': disableVoting
          },
          className
        )}
        disabled={disableVoting}
        data-testid="VoteButton"
      >
        <span
          className={cx(
            'lia-vote-count',
            { 'lia-vote-count-kudoed': !disableVoting && kudoed },
            'lia-vote-text-color'
          )}
        >
          {kudosSum}
        </span>
        {getVoteIcon()}
      </Button>
    );
  }

  /**
   * Renders the vote component for card and standard view
   */
  function renderVoteComponentForCardOrStandardView() {
    return (
      <>
        <Button
          aria-label={formatMessage('vote.ariaLabel', { value: kudoed })}
          onClick={handleKudoEvent}
          title={getTooltipText()}
          variant={ButtonVariant.NO_VARIANT}
          style={ideaTextStyles}
          className={cx('lia-button d-flex', className)}
          disabled={disableVoting}
          data-testid="VoteButton"
          aria-pressed={kudoed}
        >
          <div
            className={cx(
              'lia-vote-text-icon',
              { 'lia-vote-completed': !isAnonymous && (!showKudosAction || isVotingComplete) },
              {
                'lia-vote-standard': isStandardMessageView
              },
              { 'lia-vote-card': isCardMessageView }
            )}
          >
            <span
              className={cx(
                'lia-vote-text',
                { 'lia-vote-text-color': isStandardMessageView || isCardMessageView },
                {
                  'lia-vote-text-kudoed': (isCardMessageView && kudoed) || disableVoting
                }
              )}
            >
              {disableVoting
                ? formatMessage('votes')
                : formatMessage('voteText', { value: kudoed })}
            </span>
            {!disableVoting && getVoteIcon()}
          </div>
          {isCardMessageView && <VoteCountTitle />}
        </Button>
        {isStandardMessageView &&
          (kudosSum > 0 ? (
            renderKudosListModal(VoteCountTitle, 'lia-vote-count-button lia-g-is-number')
          ) : (
            <VoteCountTitle />
          ))}
      </>
    );
  }

  /**
   * Renders the vote component for ideas
   */
  function renderVoteComponent() {
    if (isInlineMessageView) {
      return renderVoteComponentForInlineView();
    } else {
      return renderVoteComponentForCardOrStandardView();
    }
  }

  /**
   * Renders the Kudos component
   */
  function renderKudosButton() {
    return (
      <div className={cx('d-flex align-items-center', className)}>
        <Button
          aria-label={formatMessage('kudo.ariaLabel', { value: kudoed })}
          onClick={handleKudoEvent}
          disabled={
            (isFullyRegistered && !showKudosAction) || (!isFullyRegistered && !ssoPropertiesReady)
          }
          variant={ButtonVariant.LINK}
          className={cx('lia-g-action-btn lia-kudo-btn', {
            'lia-is-kudoed': kudoed
          })}
          data-testid="KudosButton"
          aria-pressed={kudoed}
        >
          <Icon
            icon={Icons.LikeIcon}
            size={IconSize.PX_16}
            title={formatMessage('kudo')}
            className={cx('lia-icon', { 'lia-icon-animate-in': kudoed && isActive })}
          />
          {useText && (
            <span className={cx('lia-kudo-text')}>{formatMessage(kudoed ? 'kudoed' : 'kudo')}</span>
          )}
        </Button>
        {useCount && (
          <>
            {useText && (
              <div role="separator" className={cx('lia-g-divider-element lia-kudo-divider')} />
            )}
            {kudosSum > 0 ? (
              renderKudosListModal(KudosCountTitle, kudosCountClass)
            ) : (
              <Button
                aria-label="kudos count"
                variant={ButtonVariant.LINK}
                disabled
                className={cx(kudosCountClass)}
                aria-disabled="true"
              >
                <KudosCountTitle />
              </Button>
            )}
          </>
        )}
      </div>
    );
  }

  return <>{showLikeButton ? renderKudosButton() : renderVoteComponent()}</>;
};

export default React.memo(KudosButton);
